import { SvgIconProps } from "@mui/material";

const Radio10Logo: React.FC<SvgIconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="108" height="40" viewBox="0 0 108 40" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.0397 0.906067C67.7547 0.906067 67.4133 0.958688 67.0053 1.0808L56.2447 4.05674C55.4374 4.28302 54.9284 4.99395 54.815 5.80202L54.3881 8.05692C54.22 9.25453 54.9298 10.2252 56.1272 10.2252H57.9446L53.4651 35.8278C53.2291 37.5065 54.3987 38.8669 56.077 38.8669H60.1227C61.8416 38.8669 63.4311 37.4737 63.6726 35.7544L69.3763 2.88167C69.3763 2.88167 69.7874 0.906067 68.0397 0.906067Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.7535 19.9999C97.368 25.5138 92.5684 29.9998 87.0547 29.9998C81.5407 29.9998 77.369 25.5138 77.7544 19.9999C78.1398 14.486 82.9394 10.0001 88.4531 10.0001C93.9669 10.0001 98.1388 14.486 97.7535 19.9999ZM89.1523 0C82.5136 0 76.4081 3.23824 72.4228 8.21812L68.9327 28.1765C71.572 35.1438 78.224 40 86.3555 40C97.4007 40 106.981 31.0457 107.753 19.9999C108.525 8.95442 100.198 0 89.1523 0Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8393 25.2306C14.3495 25.2306 14.9709 25.1428 15.3702 25.0549L15.6809 23.143C15.2592 23.0548 14.7489 22.989 14.2609 22.989C13.174 22.989 12.597 23.3404 12.4865 23.9999C12.3306 24.8349 12.8634 25.2306 13.8393 25.2306ZM13.4179 27.978C10.3122 27.978 8.73765 26.2199 9.18137 23.8239C9.55786 21.692 11.377 20.7473 14.2166 20.7473C14.8823 20.7473 15.5475 20.8129 16.1026 20.9232L16.1465 20.5715C16.2978 19.433 16.0355 18.9014 14.6379 18.9014C13.6616 18.9014 12.6637 19.0991 11.9984 19.4065C11.7545 19.5164 11.5771 19.4725 11.4882 19.2305L10.8005 17.4288C10.7338 17.2307 10.7338 17.0772 10.8672 16.989C11.9317 16.3517 13.5957 15.9561 15.3925 15.9561C18.4098 15.9561 19.9618 17.9341 19.3633 21.2307L18.3646 26.7908C18.3207 26.989 18.2321 27.1425 18.0321 27.2087C16.7675 27.6481 15.1262 27.978 13.4179 27.978Z"
      fill="#000105"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.553 25.0448C27.0852 25.0448 27.6615 24.9567 28.0165 24.8023L29.0144 19.1122C28.6595 18.8701 28.105 18.7375 27.551 18.7375C26.4863 18.7375 25.7985 19.3992 25.555 20.7445L25.1335 23.1042C24.8675 24.4931 25.355 25.0448 26.553 25.0448ZM26.2421 27.978C23.0265 27.978 21.2525 25.7943 21.7627 22.8615L22.2281 20.3253C22.6939 17.7007 24.7123 15.9147 27.3514 15.9147C28.1717 15.9147 28.9481 16.0913 29.5023 16.3776L30.1878 12.4839C30.2326 12.2628 30.4437 12.1309 30.6656 12.1309H33.1527C33.4186 12.1309 33.5515 12.3076 33.5076 12.5497L31.0104 26.7649C30.9884 26.9634 30.8775 27.0959 30.6775 27.1618C29.4137 27.6688 27.7063 27.978 26.2421 27.978Z"
      fill="#000105"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.4076 27.7959C34.1608 27.7959 34.0262 27.6188 34.0707 27.3761L35.9692 16.6518C35.9914 16.4313 36.1487 16.3204 36.3729 16.3204H38.9767C39.2459 16.3204 39.3813 16.4748 39.336 16.7183L37.4543 27.4422C37.4092 27.6634 37.275 27.7959 37.0278 27.7959H34.4076Z"
      fill="#000105"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.7819 14.4988C36.5301 14.4988 36.3935 14.3235 36.4388 14.0822L36.8015 12.0955C36.8241 11.8763 36.9842 11.7665 37.2125 11.7665H39.8808C40.1552 11.7665 40.2927 11.9198 40.2466 12.1615L39.8845 14.1477C39.8385 14.3673 39.7019 14.4988 39.4501 14.4988H36.7819Z"
      fill="#000105"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.9013 25.0987C47.0578 25.0987 47.7692 24.4836 47.9473 23.4502L48.4586 20.6369C48.6368 19.648 48.1695 18.8353 47.1022 18.8353C46.0345 18.8353 45.2787 19.494 45.1005 20.4835L44.5893 23.3189C44.3888 24.3954 44.8559 25.0987 45.9013 25.0987ZM45.5229 27.978C42.5432 27.978 40.7412 25.7804 41.2525 22.9889L41.7202 20.2635C42.2093 17.6043 44.3888 15.9561 47.4802 15.9561C50.527 15.9561 52.3062 18.1755 51.8391 20.8573L51.3278 23.6263C50.8166 26.3959 48.7925 27.978 45.5229 27.978Z"
      fill="#000105"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.364114 27.7959C0.0974429 27.7959 -0.0358249 27.6183 0.00832763 27.3747L1.76498 17.3083C1.80981 17.1087 1.92106 16.9762 2.09876 16.8872C3.38836 16.2889 4.81137 15.9561 6.21264 15.9561C6.81295 15.9561 7.41299 16.0227 8.05827 16.1552C8.3028 16.2003 8.41406 16.3776 8.36923 16.5987L7.96929 18.7722C7.94688 19.016 7.76919 19.1269 7.52466 19.1046C7.14646 19.0384 6.70169 18.9933 6.23465 18.9933C5.65675 18.9933 5.16716 19.1046 4.78936 19.2594L3.34394 27.441C3.29925 27.663 3.16625 27.7959 2.94373 27.7959H0.364114Z"
      fill="#000105"
    />
  </svg>
);

export default Radio10Logo;
